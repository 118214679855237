<template>
  <div>
    <div id="page" v-if="ticket.capacitySegment.showtime">
      <ticket v-for="index in 8" :key="index" :ticket="ticket"></ticket>
    </div>

    <div id="guides">
      <hr style="top: 0.5in;" />
      <hr style="top: 5.5in;" />
      <hr style="top: 10.5in;" />
      <hr class="vr" style="left: 0.25in;" />
      <hr class="vr" style="left: 2.25in;" />
      <hr class="vr" style="left: 4.25in;" />
      <hr class="vr" style="left: 6.25in;" />
      <hr class="vr" style="left: 8.25in;" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Ticket from '../components/Ticket.vue';

export default {
  name: 'Tickets',
  components: { Ticket },
  props: {
    globals: Object,
  },
  data() {
    return {
      ticket: {
        capacitySegment: {
          showtime: false
        },
        registrationId: false
      },
    }
  },
  methods: {
    async loadHandler() {
      const request = {
        url: 'https://api.magicconvention.com' + '/v3/showtimes/' + this.$route.query.id,
        headers: {'Authorization': 'bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJpYXQiOjE3MjIzNTYyNDgsImV4cCI6MTcyMjQ0MjY0OCwiYXVkIjoiaHR0cHM6Ly9hcGkubWFnaWNjb252ZW50aW9uLmNvbSIsImlzcyI6Im1sYXBpIiwic3ViIjoiNzRhMzdjMWVmNjBmZjVkODAyNjI1YTJjOGEyYjg4MDIiLCJqdGkiOiJjZjBlNmI4Yi1jY2UxLTQwMWUtYWRhZS0yNDdjOTAwMTA0YWUifQ.nkCpMi7L9FOOOeM-U5Q-11afib4ZsF8B_HxjBUaAKVQ'},
        params: {
          $eager: '[show]',
        }
      }

      const result = await axios(request).catch(error => {
        console.log(error);
      })
      console.log(result.data);

      this.ticket.capacitySegment.showtime = result.data;
      this.ticket.registrationId = result.data.id + '-CS'

    }
  },
  created() {
    this.loadHandler();
  }
}
</script>

<style>

#page {
  position: absolute;
  top: 0.5in;
  /* left: 0.25in; */
  left: 0.3125in;
}

.ticket {
  transform: rotate(90deg) translate(0, -100%);
	transform-origin: top left;
  position: inherit;
}

.ticket:nth-of-type(even) {
  top: 5in;
}

.ticket:nth-of-type(3), .ticket:nth-of-type(4) {
  left: 2in;
}

.ticket:nth-of-type(5), .ticket:nth-of-type(6) {
  left: 4in;
}

.ticket:nth-of-type(7), .ticket:nth-of-type(8) {
  left: 6in;
}

/* START: GUIDES */
@media print
{    
    #guides
    {
        /* display: none !important; */
    }
}

#guides {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
hr {
  position: absolute;
  width: 8.5in;
  height: 0;
  border-top: 4px dashed orange;
  border-bottom: none;
  margin: -2px 0 0 0;
  padding: 0;
}

hr.vr {
  width: 0;
  height: 11in;
  border: none;
  border-left: 4px dashed orange;
  margin: 0 0 0 -2px;
}
/* END: GUIDES */
</style>